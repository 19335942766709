<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="profile.sidebar"
    mobile-break-point="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <v-list-item>
        <v-list-item-avatar
          class="align-self-center"
          contain
        >
          <v-img
            v-bind:src="profile.logo"
            max-height="30"
            contain
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="display-1"
            v-text="profile.title"
            style="min-height: 30px; display: flex; align-items: center;"
          />
          <div class="dashboard-version">
            v{{ dashboardVersion }}
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item
          v-if="user && user.configurations && user.configurations.menu_items && user.configurations.menu_items.indexOf(item.id) > -1 && hasPermission('menu', item.id)"
          :key="`item-${i}`"
          :item="item"
          :data-id="item.id"
        />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  import axios from 'axios'
  import Utils from '@/services/Utils'

  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      dashboardVersion: window.dashboardVersion,
      hasPermission: Utils.hasPermission,
      user: Utils.getUser(),
      items: [
        {
          icon: 'mdi-view-dashboard',
          id: 'dashboard',
          title: 'dashboard',
          to: '/home/dashboard',
        },
        {
          icon: 'mdi-account-group',
          id: 'clients',
          title: 'clients',
          to: '/home/clients',
        },
        {
          icon: 'mdi-content-paste',
          id: 'training_plans_base',
          title: 'training_plans_base',
          to: '/home/trainingPlans',
        },
        {
          icon: 'mdi-file-pdf-box',
          id: 'pdf_training_plans_base',
          title: 'pdf_training_plans_base',
          to: '/home/pdftrainingPlans',
        },
        {
          icon: 'mdi-file-pdf-box',
          id: 'plans_base',
          title: 'plans_base',
          to: '/home/plans',
        },
        {
          icon: 'mdi-content-paste',
          id: 'trains_base',
          title: 'trains',
          to: '/home/trains',
        },
        {
          icon: 'mdi-dumbbell',
          id: 'workouts',
          title: 'exercises',
          to: '/home/workouts',
        },
        {
          icon: 'mdi-dumbbell',
          id: 'classes_workouts',
          title: 'classes_exercises',
          to: '/home/workouts',
        },
        {
          icon: 'mdi-notebook-multiple',
          id: 'nutrition_base',
          title: 'nutrition_base',
          to: '/home/nutritionBase',
        },
        {
          icon: 'mdi-notebook-multiple',
          id: 'food_plan_base',
          title: 'nutrition_plans_base',
          to: '/home/foodPlansBase',
        },
        {
          icon: 'mdi-notebook-multiple',
          id: 'diets_base',
          title: 'diets_base',
          to: '/home/dietsBase',
        },
        {
          icon: 'mdi-nutrition',
          id: 'recipes',
          title: 'recipes',
          to: '/home/recipes',
        },
        {
          icon: 'mdi-food-variant',
          id: 'foods',
          title: 'foods',
          to: '/home/foods',
        },
        {
          icon: 'mdi-blender',
          id: 'supplements',
          title: 'supplements',
          to: '/home/supplements',
        },
        {
          icon: 'mdi-table-of-contents',
          id: 'contents',
          title: 'contents',
          to: '/home/contents',
        },
        {
          icon: 'mdi-account-hard-hat',
          id: 'employees',
          title: 'employees',
          to: '/home/employees',
        },
        {
          icon: 'mdi-account-credit-card',
          id: 'payments_packs',
          title: 'payments_packs',
          to: '/home/paymentsPacks',
        },
        {
          icon: 'mdi-clipboard-list-outline',
          id: 'promotional_codes',
          title: 'promotional_codes',
          to: '/home/promotionalCodes',
        },
        {
          icon: 'mdi-handshake',
          id: 'partnerships',
          title: 'partnerships',
          to: '/home/partnerships',
        },
        {
          icon: 'mdi-video',
          id: 'streaming',
          title: 'streaming',
          to: '/home/streaming',
        },
        {
          icon: 'mdi-bell-ring',
          id: 'notifications',
          title: 'notifications',
          to: '/home/notifications',
        },
        {
          icon: 'mdi-video-account',
          id: 'lives',
          title: 'lives',
          to: '/home/lives',
        },
        {
          icon: 'mdi-finance',
          id: 'statistics',
          title: 'statistics',
          to: '/home/statistics',
        },
        {
          icon: 'mdi-calendar',
          id: 'calendar',
          title: 'calendar',
          to: '/home/calendar',
        },
        {
          icon: 'mdi-calendar',
          id: 'bookings',
          title: 'bookings',
          to: '/home/bookings',
        },
        {
          icon: 'mdi-chart-box-outline',
          id: 'mkt_clint',
          title: 'mkt_clint',
          to: '/home/mktClint',
        },
        {
          icon: 'mdi-tab-search',
          id: 'scouting',
          title: 'scouting',
          to: '/home/scouting',
        },
        {
          icon: 'mdi-calendar-check',
          id: 'tasks',
          title: 'tasks',
          to: '/home/tasks',
        },
        {
          icon: 'mdi-cast-education',
          id: 'tutorials',
          title: 'tutorials',
          to: '/home/tutorials',
        },
      ],
    }),

    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      profile () {
        const user = Utils.getUser()
        return {
          avatar: true,
          logo: user ? 'https://ptclient.mkgest.com/dashboard/' + Math.abs(axios.defaults.ptId) + '/' + (window.jsonAdmin && window.jsonAdmin.logo ? window.jsonAdmin.logo : 'logo.jpg') : '',
          title: user ? user.name : '',
          sidebar: user ? 'https://ptclient.mkgest.com/dashboard/' + Math.abs(axios.defaults.ptId) + '/sidebar.jpg' : '',
        }
      },
    },

    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(window.strings[item.title] ? window.strings[item.title] : item.title),
        }
      },
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
